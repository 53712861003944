<template>
  <div class="flex-1 mt-1 bg-gray-100 px-4 pt-12 mb-20 items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
        <div class="flex">
          <router-link :class="$colors.primary" class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
            to="/expeditions">
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
            {{ $t("new_expidition") }}
          </div>
          <div v-else class="text-2xl font-bold text-green-500">
            {{ $t("edit_expidition") }}
          </div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6 pt-2">
          <div>
            <!-- ************************************************************************************ -->
            <!-- ************************************Select Colis***********************************-->
            <div class="px-6 mt-3">
              <div class="flex w-full space-x-2">
                <div class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                  <div class="flex w-full">
                    <span class="rounded-full h-12 w-12 bg-gray-200">
                      <i class="material-icons p-3 text-green-400">person</i>
                    </span>

                    <div class="ml-5 w-full">
                      <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                        {{ $t("package_information") }}
                      </div>
                      <div class="text-sm font-light text-gray-500"></div>
                      <div class="w-full space-y-4">
                        <div class="grid lg:grid-cols-2 gap-6">
                          <div
                            class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="customerFullName" class="bg-white text-gray-700 px-1">
                                  {{ $t("number_of_packages") }}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <input id="customerFullName" autocomplete="false" tabindex="0" type="number" min="1"
                                v-model="formData.numberOfPackages"
                                class="py-2 text-xs px-2 outline-none block h-full w-full" />
                            </p>
                          </div>

                          <div
                            class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="Weight" class="bg-white text-gray-700 px-1">{{ $t("weight") }}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <div class="w-full rounded-md inline-flex">
                              <input id="Weight" autocomplete="false" tabindex="0" type="number" min="1"
                                v-model="formData.weight" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                              <div class="rounded-md w-1/12 bg-gray-200 text-center">
                                {{ $t("kg") }}
                              </div>
                            </div>
                          </div>

                          <div
                            class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="customerPhone" class="bg-white text-gray-700 px-1">{{ $t("from") }}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <v-select @input="setSelectedFromContry" v-model="formData.country" :options="options"
                                label="name">
                                <template slot="option" slot-scope="option">
                                  <img class="flag-img" :src="$f.getCountryCode(option.code)" />
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </p>
                          </div>

                          <div v-if="features['multi-warehouses'].isActive"
                            class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="customerPhone" class="bg-white text-gray-700 px-1">{{ $t("to") }}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <v-select @input="setSelectedToContry" v-model="formData.country_to" :options="warehouses"
                                label="name">
                                <template slot="option" slot-scope="option">
                                  <img class="flag-img" :src="$f.getCountryCode(option.code)" />
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </p>
                          </div>

                          <div
                            class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="customerAddress" class="bg-white text-gray-700 px-1">{{
                                  $t("expedition_date") }}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <input id="dateexp" autocomplete="false" tabindex="0" type="datetime-local"
                                v-model="formData.date" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                            </p>
                          </div>

                          <div
                            class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="customerShippingAddress" class="bg-white text-gray-700 px-1">{{
                                  $t("transport_mode") }}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <select id="type" name="seller" autocomplete="type" v-model="formData.transportMode"
                                class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                                <option selected disabled :value="null">
                                  {{ $t("transport_mode") }}
                                </option>
                                <option v-for="(trans, index) in transports" :key="index" :value="trans">
                                  {{ trans }}
                                </option>
                              </select>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ****************************** Order Information *********************************************-->
              </div>
              <!-- Status and seller Administartion -->
              <div class="bg-white rounded-lg p-6" v-if="
                currentUser.type == 'Admin' ||
                currentUser.type == 'CEO' ||
                currentUser.type == 'StockManager'
              ">
                <div class="grid lg:grid-cols-2 gap-6">
                  <div v-if="features['multi-sellers'].isActive"
                    class="border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="seller" class="bg-white text-gray-700 px-1">{{ $t("seller") }}
                          <span :class="$colors.required">*</span></label>
                      </p>
                    </div>
                    <p>
                      <v-select label="fullName" @search="searchSellers($event, 'exp')" v-model="formData.seller"
                        :options="sellers"></v-select>
                    </p>
                  </div>
                  <div v-if="formData._id"
                    class="border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="seller" class="bg-white text-gray-700 px-1">{{ $t("status") }}
                          <span :class="$colors.required">*</span></label>
                      </p>
                    </div>
                    <p>
                      <select name="status" autocomplete="type" v-model="formData.status"
                        class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                        <option class="bg-white" selected :value="null">
                          {{ $t("status") }}
                        </option>
                        <option class="bg-white text-gray-600" v-for="(value, index) in status" :key="index"
                          :value="value">
                          {{ value }}
                        </option>
                      </select>
                    </p>
                  </div>
                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="dateexpar" class="bg-white text-gray-700 px-1">{{ $t("arrival_date") }}
                          <span :class="$colors.required">*</span></label>
                      </p>
                    </div>
                    <p>
                      <input id="dateexpar" type="datetime-local" v-model="formData.arrivalDate"
                        class="py-2 text-xs px-2 outline-none block h-full w-full" />
                    </p>
                  </div>
                  <div
                    class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="customerPhone" class="bg-white text-gray-700 px-1">{{ $t("expedition_fees") }}
                          <span :class="$colors.required">*</span></label>
                      </p>
                    </div>
                    <p>
                      <input id="fees" autocomplete="false" tabindex="0" type="number" min="0" v-model="formData.fees"
                        class="py-2 text-xs px-2 outline-none block h-full w-full" />
                    </p>
                  </div>
                  <!-- <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                  <label for="customerPhone" class="bg-white text-gray-700 px-1">Paid 
                                      <span :class="$colors.required">*</span></label>
                              </p>
                          </div>
                               <p class="flex items-center justify-center w-full">
                              
                                <label  for="toogleA" class="flex ml-5 items-center cursor-pointer">
                                  <div class="relative">
                                    <input id="toogleA" type="checkbox" class="hidden" v-model="formData.paid" />
                                    <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                                    <div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                                  </div>
                                  
                                  <div class="ml-3 text-gray-700">{{ formData.paid  }}</div>
                                </label>
                              </p>
                      </div> -->
                </div>
              </div>

              <!-- ********************************************************Product Table assigned to expedition **********************************-->
              <div class="flex w-full">
                <div class="flex space-x-2 w-full shadow-xs mt-1 rounded-lg">
                  <div v-if="formData.status != 'received'" class="flex w-full justify-between items-center h-auto p-6">
                    <div class="flex w-full">
                      <span class="rounded-full h-12 w-12 bg-gray-200">
                        <i class="material-icons p-3 text-green-500">local_mall</i>
                      </span>
                      <div class="ml-3 w-full p-2 pb-8">
                        <div class="w-full flex text-base font-semibold text-gray-600">
                          <p class="w-1/2">{{ $t("products") }}</p>
                          <div class="w-full">
                            <button @click="OpenPopUp"
                              class="flex text-white mx-auto max-w-sm rounded-md text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right">
                              {{ $t("select_product") }}
                            </button>
                          </div>
                        </div>

                        <div class="mt-4 overflow-x-auto">
                          <table v-if="formData.details" class="min-w-full table item leading-normal relative">
                            <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                            <thead>
                              <tr>
                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  ID
                                </th>
                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  {{ $t("name") }}
                                </th>
                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  {{ $t("commodity_value") }}
                                  <sup v-if="wharhouse" class="text-black">({{ wharhouse.currency }})</sup>
                                </th>

                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  {{ $t("quantity") }}
                                </th>

                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  {{ $t("actions") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, index) in formData.details" :key="index">
                              <tr>
                                <td class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">
                                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                    <b>{{ item.product.id }}</b>
                                  </div>
                                </td>
                                <td class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">
                                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                    {{ item.product.name }}
                                  </div>
                                </td>

                                <td class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">
                                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                    <input type="number" v-model="item.merchandise_value" min="0"
                                      class="px-2 py-2 border focus:ring-gray-500 focus:border-gray-900 sm:text-sm border-gray-600 rounded-md focus:outline-none text-gray-900" />
                                  </div>
                                </td>

                                <td class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">
                                  <div class="flex space-x-6">
                                    <button :class="$colors.textPrimary"
                                      class="bg-green-600 rounded-md w-6 h-6 flex items-center focus:outline-none"
                                      @click="addQuantity(index)">
                                      <span class="font-bold text-2xl text-white">
                                        <i class="material-icons mt-2">add</i>
                                      </span>
                                    </button>

                                    <span :class="$colors.textPrimary"
                                      class="text-gray-700 text-sm h-6 flex items-center focus:outline-none">
                                      <input class="focus:outline-none w-12 text-center" type="text"
                                        v-model="item.quantity.sent" />
                                    </span>

                                    <button :class="$colors.textPrimary" @click="removeQuantity(index)"
                                      class="bg-green-600 rounded-md w-6 h-6 text-white flex items-center focus:outline-none">
                                      <span class="font-bold text-2xl text-white mt-1">
                                        <i class="material-icons">remove</i>
                                      </span>
                                    </button>
                                  </div>
                                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900"></div>
                                </td>

                                <td class="py-4 whitespace-no-wrap border-b border-gray-200">
                                  <div class="flex justify-center">
                                    <button @click="removeProduct(item, index)" :class="$colors.actionTable"
                                      class="rounded-full flex focus:outline-none w-8 h-8 mr-3 mt-1">
                                      <i class="material-icons mx-1">delete</i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--*******************  Product Order ***************************************-->
                  <div v-if="formData._id && formData.status == 'received'"
                    class="flex w-full justify-between items-center h-auto p-6">
                    <div class="flex w-full mt-1">
                      <span class="rounded-full h-12 w-12 bg-gray-200">
                        <i class="material-icons p-3 text-green-400">local_mall</i>
                      </span>
                      <div class="ml-5 w-full pb-4">
                        <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                          {{ $t("expedition_products") }}
                        </div>
                        <div class="w-full space-y-4">
                          <div class="w-full">
                            <table class="min-w-full w-full">
                              <thead class="text-white">
                                <tr
                                  class="bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                  <th class="p-2 justify-center">
                                    {{ $t("image") }}
                                  </th>
                                  <th class="p-2 justify-center">
                                    {{ $t("name") }}
                                  </th>
                                  <th class="p-2 justify-center">
                                    {{ $t("quantity_sent") }}
                                  </th>
                                  <th colspan="2" class="p-2 justify-center">
                                    {{ $t("quantity_received") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="flex-1 sm:flex-none">
                                <tr v-for="it in formData.details" :key="it._id"
                                  class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                  <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                                    <div>
                                      <img :alt="it.product.name" :src="it.product.picture ||
                                        'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                                        " class="h-16 w-16 rounded-md mx-auto" />
                                    </div>
                                  </td>
                                  <td
                                    class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                    <b>{{ it.product.name }}</b>
                                  </td>
                                  <td
                                    class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                    <input id="sent" :disabled="CheckExpeditionRecived" type="number" min="0"
                                      v-model="it.quantity.sent"
                                      class="py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full" />
                                  </td>
                                  <td
                                    class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                    <table class="min-w-full">
                                      <thead>
                                        <tr>
                                          <th
                                            class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider">
                                            Ok/Good
                                          </th>
                                          <th
                                            class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider">
                                            Defective
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody class="bg-white">
                                        <tr>
                                          <td class="px-6 py-4 border whitespace-no-wrap">
                                            <div class="flex items-center">
                                              <input id="Received" :disabled="CheckExpeditionRecived
                                                " type="number" min="0" :max="it.quantity.sent"
                                                v-model="it.quantity.received"
                                                class="py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full" />
                                            </div>
                                          </td>
                                          <td class="px-6 py-4 border whitespace-no-wrap">
                                            <div class="flex items-center">
                                              <input id="Defective" :disabled="CheckExpeditionRecived
                                                " type="number" min="0" :max="it.quantity.sent"
                                                v-model="it.quantity.defective"
                                                class="py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full" />
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ************************************************************************************************-->
            </div>
            <!-- button save -->
            <div class="w-full">
              <div class="pt-3 flex btn-bottom-toolbar justify-end">
                <button :disabled="loading"
                  class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
                  @click="save">
                  {{ $t("save") }}
                </button>
              </div>
            </div>
            <!--end button save -->
            <!-- ***********************************Pop up Affectation products to Order************************************************ -->
            <vue-modal-2 name="Modal_products" :headerOptions="{ title: $t('add_products') }" :noFooter="true"
              modalSize="lg" @on-close="closePopUp">
              <div class="flex p-5 pt-0 pb-1 flex-col w-full h-auto mb-13">
                <!-- Header -->
                <div class="flex w-full h-auto justify-center items-center">
                  <div class="flex mb-3 w-full h-auto py-2 justify-center items-center text-2xl font-bold">
                    <span class="w-screen md:w-full h-10 cursor-pointer border border-gray-200 rounded text-sm flex">
                      <input type="search" name="search" v-model="searchText" :placeholder="$t('search')"
                        class="flex-grow px-4 rounded-l-full rounded-r-full text-sm focus:outline-none" />
                      <i class="material-icons text-green-400 m-2 mr-5 text-lg w-4 h-4">search</i>
                    </span>
                  </div>
                </div>
                <div>
                  <div v-if="products.length <= 0" class="py-2">
                    <span class="w-full flex justify-center text-center py-20 border-b border-gray-200 text-base">
                      <p class="font-semibold flex text-green-500 whitespace-no-wrap">
                        <span class="">{{ $t("no_products_found") }}</span>
                      </p>
                    </span>
                  </div>
                  <div v-for="(item, index) in resultSearchUsers" :key="index" :value="item._id"
                    class="flex space-x-5 items-center justify-between h-auto py-1 px-5 rounded border border-gray-300 mb-2">
                    <span>
                      <input type="checkbox" class="form-checkbox inline-block text-green-500 border-blue-500"
                        @change="addProductsToTemp(item, index)" v-model="item.check" />

                      <img :alt="item.name" :src="item.picture ||
                        'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                        " class="inline-block h-16 mx-4 rounded-md" />
                      <div class="inline-block">
                        <h3 class="text-gray-80">{{ item.name }}</h3>
                        <p class="text-gray-600 text-xs">
                          {{ exceptDes(item.description) }}
                        </p>
                      </div>
                    </span>
                    <!--<div class="justify-center text-xs text-center bg-blue-100 font-semibold rounded-full text-green-500 ">
                      <p>{{ item.price }} <sup class="text-black">{{wharhouse.currency }}</sup></p>
                    </div>-->
                  </div>
                </div>
              </div>

              <div class="modal-footer px-5 border0-t text-right">
                <button @click="closePopUp"
                  class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">
                  {{ $t("cancel") }}
                </button>
                <button @click="ConfirmOperation"
                  class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right">
                  {{ $t("confirm") }}
                </button>
              </div>
            </vue-modal-2>
            <!-- ******************************************************** -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      formData: {
        numberOfPackages: 1,
        weight: 0,
        date: this.$moment(new Date()).format("yyyy-MM-DDTHH:mm"),
        arrivalDate: null,
        carrier: {
          name: "",
          phone: "",
        },
        details: [],
        status: "expedited",
        country: "",
        seller: "",
        quantity: {
          sent: 0,
        },
        transportMode: "",
      },
      currentUser: {
        role: {},
      },
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
      searchText: "",
      oldstatus: "",
      products: [],
      warehouses: [],
      show_modal: true,
      productselected: [],
      numberItemRepeated: 0,
      sellers: [],
      status: ["expedited", "received", "cancelled"],
      transports: ["road", "railway", "air", "maritime"],
      options: this.$countries,
      sellerExists: false,
      selectFromContry: "",
      selectToContry: "",
      wharhouse: null,
      loading: false,
      fullPage: false,
      CheckExpeditionRecived: false,
    };
  },
  async mounted() {
    await this.getCurrentSeller();
    await this.getUser();

    //await this.getStatus();
    await this.dataToEdit();
    await this.getWarehouses();
    this.dateMin();
  },
  methods: {
    async CheckQteSentExist(item) {
      this.CheckExpeditionRecived = false;
      if (item.status == "received") {
        for (let i in item.details) {
          const filters = {
            product: item.details[i].product._id,
            expedition: item._id,
          };
          const res = await this.$server.search("stockHistories", filters);
          if (typeof res.content.results !== "undefined") {
            this.CheckExpeditionRecived = true;
          }
        }
      }
    },
    async saveProductPrice(product) {
      if (product._id) {
        const resProductW = await this.getElementInArray(
          product.details,
          this.wharhouse._id
        );
        if (!resProductW)
          product.details.push({ price: 0, warehouse: this.wharhouse._id });

        await this.changePrice(
          product.details,
          this.wharhouse._id,
          product.newPrice
        );

        this.loading = true;
        const data = await this.$server.update("products", product);
        if (data._id) {
          this.loading = false;
          product.price = parseInt(product.newPrice);
          alert(this.$t("product_updated"), "success");
        }
      }
    },
    async changePrice(data, value, price) {
      for (var i in data) {
        if (data[i].warehouse == value.toString()) {
          data[i].price = price;
          break; //Stop this loop, we found it!
        }
      }
    },
    async getwharhouse(country) {
      const resW = await this.$server.get("warehouses", { country: country });
      if (resW.content) return resW.content;
    },
    searchSellers: _.debounce(async function (search, event) {
      if (this.selectToContry) {
        const filter = {
          type: "Seller",
          limit: this.limit,
          countries: this.selectToContry,
          name: search,
        };
        const res = await this.$server.find("users", filter);

        if (res.content.results) this.sellers = res.content.results;
        else this.sellers = [];
      } else alert("Please select a country !", "warning");
    }, 500),
    dateMin() {
      // document.getElementById('dateexp').setAttribute('min', this.$moment(Date.now()).format('yyyy-MM-DD'));
      // if(this.currentUser.type != "Seller")  document.getElementById('dateexpar').setAttribute('min', this.$moment(Date.now()).format('yyyy-MM-DD'));
    },
    exceptDes(text) {
      if (text && text.length > 60) text = text.substring(0, 60) + "...";
      return text;
    },
    setSelectedFromContry(contry) {
      if (contry) {
        this.formData.country = contry.name;
        this.selectFromContry = contry.code;
      }
    },
    async setSelectedToContry(contry) {
      if (contry) {
        this.formData.country_to = contry.name;
        this.selectToContry = contry.code;
        this.products = [];
        this.formData.details = [];
        this.wharhouse = await this.getwharhouse(contry.code);
        await this.getSellers(contry.code);
        // This code sets the seller value based on the default seller and multi-seller feature.
        this.formData.seller =
          this.defaultSeller && !this.features["multi-sellers"].isActive
            ? this.sellers.find((s) => s._id == this.defaultSeller)
            : this.formData.seller;
      }
    },
    setStatusSelected(status) {
      if (status) {
        this.formData.status = status;
      } else {
        this.formData.status = "";
      }
    },

    async getCurrentSeller() {
      const res = await this.$server.me("users");
      if (res.content) {
        const currentUser = res.content;
        if (currentUser.type == "Seller")
          this.formData.seller = currentUser._id;
      }
    },
    async getSellers(country) {
      if (this.currentUser.type != "Seller") {
        const res = await this.$server.search("users", {
          type: "Seller",
          countries: country,
        });
        if (res.content.results) {
          this.sellers = res.content.results;
          this.sellerExists = true;
        }
      }
    },
    async getUser() {
      const res = await this.$server.me("users");

      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },
    async getProducts(filters) {
      const resW = await this.$server.get("warehouses", {
        country: this.selectToContry,
      });
      if (this.formData.seller) {
        filters.seller = this.formData.seller._id;
        filters.limit = 2000;

        const res = await this.$server.search("products", filters);
        if (res.content.results) {
          this.products = res.content.results;
          this.paginate.total = res.content.total;
          this.paginate.lastpage = res.content.last_page;
          this.paginate.per_page = res.content.per_page;
          this.paginate.currentpage = res.content.current_page;

          for (let i in this.products) {
            if (this.products[i].seller)
              this.products[i].seller = this.products[i].seller._id;
            const resProductW = await this.getElementInArray(
              this.products[i].details,
              resW.content._id
            );
            if (resProductW) this.products[i].price = resProductW.price || 0;
            else this.products[i].price = 0;
            this.products[i].newPrice = 0;
          }
        } else this.products = [];
      }
    },
    async getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let whar = o.warehouse;
        if (o.warehouse._id) whar = o.warehouse._id;
        return whar == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    async save() {
      if (this.formData._id) {
        this.formData.seller = this.formData.seller._id;
        this.formData.country = this.selectFromContry;
        this.formData.country_to = this.selectToContry;
        this.loading = true;
        const data = await this.$server.update("expeditions", this.formData);
        this.loading = false;
        if (data && data._id) {
          this.formData.country = this.$f.getCountryNameByCode(
            this.options,
            this.selectFromContry
          );
          this.formData.country_to = this.$f.getCountryNameByCode(
            this.options,
            this.selectToContry
          );
          alert(this.$t("expedition_updated"), "success");
          await this.dataToEdit();
        }
        alert(data, "warning");
      } else {
        this.formData.seller =
          this.defaultSeller && !this.features["multi-sellers"].isActive
            ? this.sellers.find((s) => s._id == this.defaultSeller)
            : this.formData.seller;
        if (this.checkData()) {
          this.loading = true;
          for (let i in this.formData.details) {
            if (
              !this.formData.details[i].merchandise_value ||
              parseInt(this.formData.details[i].merchandise_value) == 0
            ) {
              alert(this.$t("commodity_value_required"), "warning");
              this.loading = false;
              return false;
              break;
            }
          }

          this.formData.country = this.selectFromContry;
          this.formData.country_to = this.selectToContry;
          const data = await this.$server.create("expeditions", this.formData);
          this.loading = false;
          if (data && data._id) {
            this.selectFromContry = this.selectToContry = "";
            this.formData = {
              carrier: {},
              details: [],
            };
            if (this.currentUser.type == "Seller") {
              this.formData.seller = this.currentUser._id;
            }
            alert(this.$t("expedition_created"), "success");
          } else alert(data, "warning");
        } else {
          alert(this.$t("all_required_field"), "warning");
        }
      }
    },
    async getWarehouses() {
      const filter = { limit: 1000 };
      const res = await this.$server.search("warehouses", filter);
      if (res.content.results) {
        let data = res.content.results;
        for (let i in data) {
          this.warehouses.push({
            name: this.$f.getCountryNameByCode(this.options, data[i].country),
            code: data[i].country,
          });
        }
      } else this.warehouses = [];
    },
    getQuantity() {
      this.formData.quantity.sent = 0;
      for (let i = 0; i < this.formData.details.length; i++) {
        this.formData.quantity.sent += this.formData.details[i].quantity;
      }
    },
    checkData() {
      if (
        this.formData.numberOfPackages &&
        this.formData.country &&
        this.formData.country_to &&
        this.formData.transportMode &&
        this.formData.date &&
        this.formData.details.length > 0 &&
        this.formData.seller
      )
        return true;
      else return false;
    },
    removeProduct(data, index) {
      this.$swal.fire({
        title: "Are you sure you want to delete this product?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let pos = this.formData.details
            .map(function (e) {
              return e.product._id;
            })
            .indexOf(data._id);

          this.formData.details.splice(pos, 1);
          data.product.check = false;
          alert(this.$t("product_deleted"), "success");
        }
      }
      );
    },

    addProductsToTemp(data, index) {
      if (data.check) {
        const detail = {
          product: data,
          quantity: {
            sent: 1,
          },
        };

        let found = 0;
        for (let i = 0; i < this.formData.details.length; i++) {
          if (this.formData.details[i].product._id == data._id) {
            found = found + 1;
            this.numberItemRepeated = this.numberItemRepeated + 1;
          }
        }
        if (found >= 1) {
        } else {
          this.productselected.push(detail);
        }
      } else {
        let pos = this.productselected
          .map(function (e) {
            return e.product._id;
          })
          .indexOf(data._id);
        this.productselected.splice(pos, 1);
      }
    },
    addQuantity(index) {
      this.formData.details[index].quantity.sent++;
    },
    removeQuantity(index) {
      if (this.formData.details[index].quantity.sent <= 1) {
      } else {
        this.formData.details[index].quantity.sent--;
      }
    },
    ViderTable() {
      this.productselected = [];
    },
    ViderProductTable() {
      this.formData.details = [];
    },
    async OpenPopUp() {
      if (!this.formData.country_to) {
        alert(this.$t("select_country"), "warning");
        return false;
      }
      if (this.formData.seller) {
        await this.getProducts({});
        if (this.products.length > 0) {
          this.$vm2.open("Modal_products");
        } else {
          if (this.currentUser.type != "Seller")
            alert(this.$t("no_products_seller"), "warning");
          else alert(this.$t("no_product_added"), "warning");
        }
      } else {
        alert(this.$t("must_select_seller"), "warning");
      }
    },
    ConfirmOperation() {
      let fount = 0;
      if (this.numberItemRepeated > 0) {
        alert(this.$t("product_already_added"), "success");
      } else {
        for (let i = 0; i < this.productselected.length; i++) {
          this.formData.details.push(this.productselected[i]);
        }
      }
      this.productselected = [];

      this.numberItemRepeated = 0;
      this.$vm2.close("Modal_products");
    },
    closePopUp() {
      this.$vm2.close("Modal_products");
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("expeditions", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        this.oldstatus = this.formData.status;
        this.wharhouse = await this.getwharhouse(this.formData.country_to);
        await this.CheckQteSentExist(this.formData);
        this.selectFromContry = this.formData.country;
        this.formData.country = this.$f.getCountryNameByCode(
          this.options,
          this.formData.country
        );
        this.selectToContry = this.formData.country_to;
        this.formData.country_to = this.$f.getCountryNameByCode(
          this.options,
          this.formData.country_to
        );
        this.formData.date = this.$moment(this.formData.date).format(
          "yyyy-MM-DDTHH:mm"
        );
        if (this.formData.arrivalDate)
          this.formData.arrivalDate = this.$moment(
            this.formData.arrivalDate
          ).format("yyyy-MM-DDTHH:mm");
        await this.getSellers(this.formData.country_to);
      }
    },
  },

  computed: {
    ...mapState({
      features: (state) => state.features.all,
      defaultSeller: (state) => state.app.defaultSeller,
      // defaultWarehouse: (state) => state.app.defaultWarehouse,
    }),
    resultSearchUsers() {
      if (this.searchText) {
        return this.products.filter((product) => {
          var regex = new RegExp(this.searchText, "i");

          return regex.test(product.name);
        });
      } else {
        return this.products;
      }
    },
  },
  created() { },
  /************************************* Pagination **************************************************/
};
</script>

<style>
.flag-img {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: text-top;
  line-height: 16px;
  margin-right: 5px;
}

.left-3 {
  left: 0.75rem;
}

.top-2 {
  top: 0.1rem;
}

.vs__dropdown-toggle {
  border: none;
}

.modal-header {
  background: #226faa;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(#226faa),
      color-stop(37%, #2989d8),
      to(#72c0d3));
  background: linear-gradient(to right, #226faa 0, #2989d8 37%, #72c0d3 100%);
  border-radius: 6px;
  color: #fff;
  padding: 18px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: transparent;
  border-bottom: 1px solid #e5e5e5;
}

.table.item thead {
  background: #3182ce4f;
  color: #fff;
  border: 0;
}

.toggle__dot {
  transition: all 0.3s ease-in-out;
}

.seller-select .vs__dropdown-toggle {
  border: none;
}

input:checked~.toggle__dot {
  transform: translateX(100%);
  background-color: #68d391;
}

/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}

dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.btn-bottom-toolbar {
  position: fixed;
  bottom: 0;
  padding: 12px;
  padding-right: 50px;
  margin: 0px 0 0 -40px;
  box-shadow: 0 -4px 1px -4px rgb(0 0 0 / 10%);
  background: #fff;
  width: calc(100% - 211px);
  z-index: 60;
  border-top: 1px solid #ededed;
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked~.toggle__dot {
  transform: translateX(100%);
  background-color: #63b3ed;
}
</style>
