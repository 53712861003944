var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-1 mt-1 bg-gray-100 px-4 pt-12 mb-20 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-2"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('router-link', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3",
    class: _vm.$colors.primary,
    attrs: {
      "to": "/expeditions"
    }
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_expidition")) + " ")]) : _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("edit_expidition")) + " ")])], 1)]), _c('div', {}, [_c('div', {
    staticClass: "bg-white rounded-lg p-6 pt-2"
  }, [_c('div', [_c('div', {
    staticClass: "px-6 mt-3"
  }, [_c('div', {
    staticClass: "flex w-full space-x-2"
  }, [_c('div', {
    staticClass: "flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("package_information")) + " ")]), _c('div', {
    staticClass: "text-sm font-light text-gray-500"
  }), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerFullName"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("number_of_packages")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.numberOfPackages,
      expression: "formData.numberOfPackages"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "customerFullName",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "number",
      "min": "1"
    },
    domProps: {
      "value": _vm.formData.numberOfPackages
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "numberOfPackages", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "Weight"
    }
  }, [_vm._v(_vm._s(_vm.$t("weight")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('div', {
    staticClass: "w-full rounded-md inline-flex"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.weight,
      expression: "formData.weight"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "Weight",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "number",
      "min": "1"
    },
    domProps: {
      "value": _vm.formData.weight
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "weight", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "rounded-md w-1/12 bg-gray-200 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("kg")) + " ")])])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerPhone"
    }
  }, [_vm._v(_vm._s(_vm.$t("from")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.options,
      "label": "name"
    },
    on: {
      "input": _vm.setSelectedFromContry
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.code)
          }
        }), _vm._v(" " + _vm._s(option.name) + " ")];
      }
    }]),
    model: {
      value: _vm.formData.country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "country", $$v);
      },
      expression: "formData.country"
    }
  })], 1)]), _vm.features['multi-warehouses'].isActive ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerPhone"
    }
  }, [_vm._v(_vm._s(_vm.$t("to")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.warehouses,
      "label": "name"
    },
    on: {
      "input": _vm.setSelectedToContry
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.code)
          }
        }), _vm._v(" " + _vm._s(option.name) + " ")];
      }
    }], null, false, 1704456886),
    model: {
      value: _vm.formData.country_to,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "country_to", $$v);
      },
      expression: "formData.country_to"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("expedition_date")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.date,
      expression: "formData.date"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "dateexp",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "datetime-local"
    },
    domProps: {
      "value": _vm.formData.date
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "date", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerShippingAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("transport_mode")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.transportMode,
      expression: "formData.transportMode"
    }],
    staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
    attrs: {
      "id": "type",
      "name": "seller",
      "autocomplete": "type"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formData, "transportMode", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "selected": "",
      "disabled": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("transport_mode")) + " ")]), _vm._l(_vm.transports, function (trans, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": trans
      }
    }, [_vm._v(" " + _vm._s(trans) + " ")]);
  })], 2)])])])])])])])]), _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'StockManager' ? _c('div', {
    staticClass: "bg-white rounded-lg p-6"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_vm.features['multi-sellers'].isActive ? _c('div', {
    staticClass: "border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(_vm._s(_vm.$t("seller")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "fullName",
      "options": _vm.sellers
    },
    on: {
      "search": function search($event) {
        return _vm.searchSellers($event, 'exp');
      }
    },
    model: {
      value: _vm.formData.seller,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "seller", $$v);
      },
      expression: "formData.seller"
    }
  })], 1)]) : _vm._e(), _vm.formData._id ? _c('div', {
    staticClass: "border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.status,
      expression: "formData.status"
    }],
    staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
    attrs: {
      "name": "status",
      "autocomplete": "type"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formData, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    staticClass: "bg-white",
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]), _vm._l(_vm.status, function (value, index) {
    return _c('option', {
      key: index,
      staticClass: "bg-white text-gray-600",
      domProps: {
        "value": value
      }
    }, [_vm._v(" " + _vm._s(value) + " ")]);
  })], 2)])]) : _vm._e(), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "dateexpar"
    }
  }, [_vm._v(_vm._s(_vm.$t("arrival_date")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.arrivalDate,
      expression: "formData.arrivalDate"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "dateexpar",
      "type": "datetime-local"
    },
    domProps: {
      "value": _vm.formData.arrivalDate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "arrivalDate", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerPhone"
    }
  }, [_vm._v(_vm._s(_vm.$t("expedition_fees")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.fees,
      expression: "formData.fees"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "fees",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.formData.fees
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "fees", $event.target.value);
      }
    }
  })])])])]) : _vm._e(), _c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "flex space-x-2 w-full shadow-xs mt-1 rounded-lg"
  }, [_vm.formData.status != 'received' ? _c('div', {
    staticClass: "flex w-full justify-between items-center h-auto p-6"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(1), _c('div', {
    staticClass: "ml-3 w-full p-2 pb-8"
  }, [_c('div', {
    staticClass: "w-full flex text-base font-semibold text-gray-600"
  }, [_c('p', {
    staticClass: "w-1/2"
  }, [_vm._v(_vm._s(_vm.$t("products")))]), _c('div', {
    staticClass: "w-full"
  }, [_c('button', {
    staticClass: "flex text-white mx-auto max-w-sm rounded-md text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": _vm.OpenPopUp
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("select_product")) + " ")])])]), _c('div', {
    staticClass: "mt-4 overflow-x-auto"
  }, [_vm.formData.details ? _c('table', {
    staticClass: "min-w-full table item leading-normal relative"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('thead', [_c('tr', [_c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" ID ")]), _c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]), _c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("commodity_value")) + " "), _vm.wharhouse ? _c('sup', {
    staticClass: "text-black"
  }, [_vm._v("(" + _vm._s(_vm.wharhouse.currency) + ")")]) : _vm._e()]), _c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity")) + " ")]), _c('th', {
    staticClass: "px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("actions")) + " ")])])]), _vm._l(_vm.formData.details, function (item, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', {
      staticClass: "px-3 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_c('b', [_vm._v(_vm._s(item.product.id))])])]), _c('td', {
      staticClass: "px-3 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.product.name) + " ")])]), _c('td', {
      staticClass: "px-3 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.merchandise_value,
        expression: "item.merchandise_value"
      }],
      staticClass: "px-2 py-2 border focus:ring-gray-500 focus:border-gray-900 sm:text-sm border-gray-600 rounded-md focus:outline-none text-gray-900",
      attrs: {
        "type": "number",
        "min": "0"
      },
      domProps: {
        "value": item.merchandise_value
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "merchandise_value", $event.target.value);
        }
      }
    })])]), _c('td', {
      staticClass: "px-3 py-2 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "flex space-x-6"
    }, [_c('button', {
      staticClass: "bg-green-600 rounded-md w-6 h-6 flex items-center focus:outline-none",
      class: _vm.$colors.textPrimary,
      on: {
        "click": function click($event) {
          return _vm.addQuantity(index);
        }
      }
    }, [_vm._m(2, true)]), _c('span', {
      staticClass: "text-gray-700 text-sm h-6 flex items-center focus:outline-none",
      class: _vm.$colors.textPrimary
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.quantity.sent,
        expression: "item.quantity.sent"
      }],
      staticClass: "focus:outline-none w-12 text-center",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": item.quantity.sent
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(item.quantity, "sent", $event.target.value);
        }
      }
    })]), _c('button', {
      staticClass: "bg-green-600 rounded-md w-6 h-6 text-white flex items-center focus:outline-none",
      class: _vm.$colors.textPrimary,
      on: {
        "click": function click($event) {
          return _vm.removeQuantity(index);
        }
      }
    }, [_vm._m(3, true)])]), _c('div', {
      staticClass: "text-xs leading-5 capitalize px-2 rounded-full text-gray-900"
    })]), _c('td', {
      staticClass: "py-4 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "flex justify-center"
    }, [_c('button', {
      staticClass: "rounded-full flex focus:outline-none w-8 h-8 mr-3 mt-1",
      class: _vm.$colors.actionTable,
      on: {
        "click": function click($event) {
          return _vm.removeProduct(item, index);
        }
      }
    }, [_c('i', {
      staticClass: "material-icons mx-1"
    }, [_vm._v("delete")])])])])])]);
  })], 2) : _vm._e()])])])]) : _vm._e(), _vm.formData._id && _vm.formData.status == 'received' ? _c('div', {
    staticClass: "flex w-full justify-between items-center h-auto p-6"
  }, [_c('div', {
    staticClass: "flex w-full mt-1"
  }, [_vm._m(4), _c('div', {
    staticClass: "ml-5 w-full pb-4"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("expedition_products")) + " ")]), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_c('thead', {
    staticClass: "text-white"
  }, [_c('tr', {
    staticClass: "bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
  }, [_c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("image")) + " ")]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_sent")) + " ")]), _c('th', {
    staticClass: "p-2 justify-center",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_received")) + " ")])])]), _c('tbody', {
    staticClass: "flex-1 sm:flex-none"
  }, _vm._l(_vm.formData.details, function (it) {
    return _c('tr', {
      key: it._id,
      staticClass: "flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
    }, [_c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2"
    }, [_c('div', [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "alt": it.product.name,
        "src": it.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.product.name))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.quantity.sent,
        expression: "it.quantity.sent"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "sent",
        "disabled": _vm.CheckExpeditionRecived,
        "type": "number",
        "min": "0"
      },
      domProps: {
        "value": it.quantity.sent
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.quantity, "sent", $event.target.value);
        }
      }
    })]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('table', {
      staticClass: "min-w-full"
    }, [_vm._m(5, true), _c('tbody', {
      staticClass: "bg-white"
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.quantity.received,
        expression: "it.quantity.received"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "Received",
        "disabled": _vm.CheckExpeditionRecived,
        "type": "number",
        "min": "0",
        "max": it.quantity.sent
      },
      domProps: {
        "value": it.quantity.received
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.quantity, "received", $event.target.value);
        }
      }
    })])]), _c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.quantity.defective,
        expression: "it.quantity.defective"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "Defective",
        "disabled": _vm.CheckExpeditionRecived,
        "type": "number",
        "min": "0",
        "max": it.quantity.sent
      },
      domProps: {
        "value": it.quantity.defective
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.quantity, "defective", $event.target.value);
        }
      }
    })])])])])])])]);
  }), 0)])])])])])]) : _vm._e()])])]), _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "pt-3 flex btn-bottom-toolbar justify-end"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])])]), _c('vue-modal-2', {
    attrs: {
      "name": "Modal_products",
      "headerOptions": {
        title: _vm.$t('add_products')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": _vm.closePopUp
    }
  }, [_c('div', {
    staticClass: "flex p-5 pt-0 pb-1 flex-col w-full h-auto mb-13"
  }, [_c('div', {
    staticClass: "flex w-full h-auto justify-center items-center"
  }, [_c('div', {
    staticClass: "flex mb-3 w-full h-auto py-2 justify-center items-center text-2xl font-bold"
  }, [_c('span', {
    staticClass: "w-screen md:w-full h-10 cursor-pointer border border-gray-200 rounded text-sm flex"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchText,
      expression: "searchText"
    }],
    staticClass: "flex-grow px-4 rounded-l-full rounded-r-full text-sm focus:outline-none",
    attrs: {
      "type": "search",
      "name": "search",
      "placeholder": _vm.$t('search')
    },
    domProps: {
      "value": _vm.searchText
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchText = $event.target.value;
      }
    }
  }), _c('i', {
    staticClass: "material-icons text-green-400 m-2 mr-5 text-lg w-4 h-4"
  }, [_vm._v("search")])])])]), _c('div', [_vm.products.length <= 0 ? _c('div', {
    staticClass: "py-2"
  }, [_c('span', {
    staticClass: "w-full flex justify-center text-center py-20 border-b border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-green-500 whitespace-no-wrap"
  }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t("no_products_found")))])])])]) : _vm._e(), _vm._l(_vm.resultSearchUsers, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "flex space-x-5 items-center justify-between h-auto py-1 px-5 rounded border border-gray-300 mb-2",
      attrs: {
        "value": item._id
      }
    }, [_c('span', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.check,
        expression: "item.check"
      }],
      staticClass: "form-checkbox inline-block text-green-500 border-blue-500",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(item.check) ? _vm._i(item.check, null) > -1 : item.check
      },
      on: {
        "change": [function ($event) {
          var $$a = item.check,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(item, "check", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(item, "check", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(item, "check", $$c);
          }
        }, function ($event) {
          return _vm.addProductsToTemp(item, index);
        }]
      }
    }), _c('img', {
      staticClass: "inline-block h-16 mx-4 rounded-md",
      attrs: {
        "alt": item.name,
        "src": item.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    }), _c('div', {
      staticClass: "inline-block"
    }, [_c('h3', {
      staticClass: "text-gray-80"
    }, [_vm._v(_vm._s(item.name))]), _c('p', {
      staticClass: "text-gray-600 text-xs"
    }, [_vm._v(" " + _vm._s(_vm.exceptDes(item.description)) + " ")])])])]);
  })], 2)]), _c('div', {
    staticClass: "modal-footer px-5 border0-t text-right"
  }, [_c('button', {
    staticClass: "flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": _vm.closePopUp
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": _vm.ConfirmOperation
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")])])])], 1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("person")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-500"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "font-bold text-2xl text-white"
  }, [_c('i', {
    staticClass: "material-icons mt-2"
  }, [_vm._v("add")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "font-bold text-2xl text-white mt-1"
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("remove")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" Ok/Good ")]), _c('th', {
    staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
  }, [_vm._v(" Defective ")])])]);

}]

export { render, staticRenderFns }